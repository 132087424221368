
.box{
    text-align: center;
    height: 50vh;
    width: 75vw;
    background-color: rgb(0, 0, 0);
    margin: 150px auto;
    background-size:cover;
    position:relative;
    cursor:pointer;
    transition: all 0.4s ease-out;
    box-shadow: 0px 35px 77px -17px rgba(0,0,0,0.44);    
}
.container{
    text-align: center;
    background: rgb(0, 0, 0);
    padding: 50px;
    color: rgb(255, 255, 255);
    
}

.title-container{
    background: rgb(0, 0, 0);
    padding: 50px 0px 5px 0px;
    font-family: "Arial";
    align-items: center;
    font-size: 50px;
    letter-spacing: 3px;
    color: rgb(255, 255, 255);
}

.hadith-text{
    font-size: '20px';
    font-style: 'italic';
    color: white;
    font-size: 25px;
}

.update-container{
    background-color: rgb(0, 0, 0);
    margin-top: 70px;
    padding: 20px;
    color: rgb(255, 255, 255);

}